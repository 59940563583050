import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import queryString from 'query-string';
import './App.css';

const verifyIfSourceAllowed = source => /^https:\/\/s39095|s44624\.cdn\.ngenix\.net\/.*$/.test(source);

function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref]
  );
  return [ref, value];
}

const playVideo = (event) => {
  console.log(event);
  try {
    document.querySelector('video').play();
  } catch (error) {}
};

function App() {
  const [hoverRef, isHovered] = useHover();

  useEffect(() => {
    window.addEventListener('message', playVideo);

    return () => {
      window.removeEventListener('message', playVideo);
    }
  });

  const { playlist, width, height, tilda } = queryString.parse(window.location.search);
  let isTilda = false;
  if (tilda === '1') {
    isTilda = true;
  }

  if (verifyIfSourceAllowed(playlist)) {
    return (
      <div className="app">
        <div ref={hoverRef} className="player-container">
          <ReactPlayer
            playing={isTilda ? isHovered : true}
            url={playlist}
            volume={isTilda ? (isHovered ? 1 : 0) : null}
            controls={!isTilda}
            config={{
              file: {
                forceHLS: true
              }
            }}
            height={height}
            width={width}
            loop={isTilda}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <div className="forbidden">403</div>
    </div>
  )
}

export default App;
